import React, { useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import BackdropIcon from "../BackdropIcon";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const StudyLink = (props) => {
  const handleButton = () => {
    getLink();
  };
  const handleClose = () => {
    setOpenError(false);
  };
  const [link, setLink] = useState("");
  const [openError, setOpenError] = useState(false);
  const [gettingLink, setGettingLink] = useState(false);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={() => openInNewTab(link)}>
        Open
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const getLink = async () => {
    const session_url = `${process.env.REACT_APP_BACKEND_PHP_URL}/studylink.php`;
    setGettingLink(true);

    var config = {
      method: "post",
      url: session_url,
      data: {
        studyid: props.id,
        sid: props.token,
      },
    };
    const res = await axios(config);
    console.log(res.data);
    setLink(res.data);
    navigator.clipboard.writeText(res.data);
    if ((res.data = 1)) {
      setGettingLink(false);
    } else {
      setGettingLink(false);
    }

    setOpenError(true);
  };
  if (gettingLink) {
    return <BackdropIcon content={<SendIcon />} />;
  }

  return (
    <>
      <Button
        sx={{
          background: "#80848c",
          fontSize: "11px",
          ":hover": {
            bgcolor: "#0290A2",
            color: "black",
          },
        }}
        onClick={handleButton}
        variant="contained"
        endIcon={<ContentCopyIcon />}
      >
        {props.text}
      </Button>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={openError}
        autoHideDuration={6000}
        message="Public link copied to clipboard"
        onClose={handleClose}
        action={action}
      />
    </>
  );
};
export default StudyLink;
