import React, { useState, useReducer } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Fade from "@mui/material/Fade";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MenuItem from "@mui/material/MenuItem";
import modalitiesList from "../modalitiesList";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
//TODO: remove the editmodality name to disabled on next release

const EditModality = (props) => {
  //clearup later with array,

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_PHP_URL}/editmodality.php`,
        {
          sid: props.token,
          id: props.id,
          mname: modalityName,
          mtype: modalityType,
          mdesc: description,
          mtime: studyTime,
          aetitle: aetitle
        }
      );
      setErrorText(res.data);
      // console.log(res.data);
      setOpenError(true);
      props.onEdit();
      if (res.data === "success") {
        setErrorText("Modified Successfully");
      } else {
        setErrorText("Something went wrong");
      }
    } catch (error) {
      if (error.response) {
        setErrorText(error.res.data);
      }
    }
    // submit modal
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [modalityName, setModalityName] = useState(props.name);
  const [description, setDescription] = useState(props.description);
  const [modalityType, setModalityType] = useState(props.type);
  const [studyTime, setStudyTime] = useState(props.pro_time);
  const [errorText, setErrorText] = useState("");
  const [aetitle, setAeTitle] = useState(props.aetitle);
  const [modalityError, setModalityError] = useState("");

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  return (
    <>
      <IconButton
        style={{ color: "white" }}
        onClick={() => handleClickOpenDialog()}
      >
        <EditIcon fontSize="small" />
      </IconButton>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Edit Modality Details of {props.name} with AE Title {props.aetitle} </DialogTitle>
        <DialogContent>
          <TextField
            name="modalityName"
            margin="dense"
            id="modalityName"
            label="Modality Name"
            type="text"
            value={modalityName}
            fullWidth
            onChange={(e) => {
              const value = e.target.value;
              // Regular expression to allow only letters, numbers, hyphens, and underscores
              const regex = /^[a-zA-Z0-9-_]+$/;
              if (!regex.test(value)) {
                setModalityError("Server name can only contain letters, numbers, hyphens, and underscores.");
              } else {
                setModalityError("");
              }
              setModalityName(value);
            }}
            error={!!modalityError}
            helperText={modalityError}
          />

          <TextField
            name="selectedModality"
            margin="dense"
            id="modalityType"
            label="Modality Type"
            type="text"
            fullWidth
            select
            value={modalityType}
            onChange={(e) => setModalityType(e.target.value)}
          >
            {modalitiesList.map(({ code, name }, index) => (
              <MenuItem key={index} value={code}>
                {name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            name="description"
            margin="dense"
            id="modalitydesc"
            label="Description"
            type="text"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <TextField
            name="aetitle"
            margin="dense"
            id="aetitle"
            label="AE Title"
            type="text"
            fullWidth
            value={aetitle}
            onChange={(e) => setAeTitle(e.target.value)}
            inputProps={{ maxLength: 16 }}
            required
          />

          <TextField
            name="studyTime"
            margin="dense"
            id="studytime"
            label="Default Study Time"
            type="text"
            fullWidth
            value={studyTime}
            onChange={(e) => setStudyTime(e.target.value)}
          />
          <br />
          <Collapse in={openError}>
            <Fade
              in={openError}
              timeout={{ enter: 1000, exit: 1000 }}
              addEndListener={() => {
                setTimeout(() => {
                  setOpenError(false);
                  setOpenDialog(false);
                }, 2000);
              }}
            >
              <Alert sx={{ mb: 2 }} variant="filled">
                {errorText}
              </Alert>
            </Fade>
          </Collapse>
          <DialogActions>
            <Button onClick={handleSubmit}>Update</Button>
            <Button onClick={handleCloseDialog}>Close</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default EditModality;
