import React, { useState, useEffect } from "react";
import UserDrawerNew from "./UserDrawerNew";
import UserDrawerMobile from "./userDrawerMobile";
const ResponsiveDrawer = () => {
    const [isMobile, setIsMobile] = useState(false)
    const handleResize = () => {
        if (window.screen.width < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }
    // useEffect(() => {
    //     // Add event listener on mount
    //     window.addEventListener('resize', handleResize);
    //     // Clean up event listener on unmount
    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, []);
    useEffect(() => {
        const mobileMediaQuery = window.matchMedia('(max-width: 767px)'); // Adjust the breakpoint as needed
    
        const handleMobileChange = (event) => {
          setIsMobile(event.matches);
        };
    
        mobileMediaQuery.addEventListener('change', handleMobileChange);
        setIsMobile(mobileMediaQuery.matches);
    
        return () => {
          mobileMediaQuery.removeEventListener('change', handleMobileChange);
        };
      }, []);


    return (
        <>
            {isMobile ? (
               <UserDrawerMobile/>
            ) : (<UserDrawerNew />
            )}
        </>
    );
};

export default ResponsiveDrawer;
