import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UploadHistory.css';

const ProgressItem = ({ item, token }) => {

    const [studyDetails, setStudyDetails] = useState(null);

    const getDetails = (series) => {
        const session_url = `${process.env.REACT_APP_BACKEND_PHP_URL}/study_details.php`;
        const config = {
            method: "post",
            url: session_url,
            data: {
                sid: token,
                series: series
            },
            withCredentials: true
        };
        axios.defaults.withCredentials = true;
        axios(config)
            .then(res => {
                setStudyDetails(res.data);
            })
            .catch(error => {
                console.error('Error fetching study details:', error);
            });
    };

    useEffect(() => {
        if (item.Content.ParentResources.length > 0) {
            getDetails(item.Content.ParentResources[0]);
        }
    }, [item, token]);
    const getStatusClass = () => {
        if (item.State === 'Failure') return 'card-failure';
        if (item.State === 'Success') return 'card-completed';
        return 'card-progressing';
    };
    const getTextClass = () => {
        if (item.State === 'Failure') return 'text-red';
        if (item.State === 'Success') return 'text-green';
        return 'text-yellow';
    };

    const progressWidth = `${item.Progress}%`;

    const formatDate = (dateString) => {
        if (!dateString || dateString.length < 8) return 'Loading...';

        const day = dateString.substring(6, 8);
        const month = dateString.substring(4, 6);
        const year = dateString.substring(0, 4);

        return `${day}/${month}/${year}`;
    };

    return (
        <div className={`card ${getStatusClass()}`}>
            {/* not implimented */}
            {/* <button className="close-button">X</button> */}
            {/* <h3>Job ID: {item.ID}</h3> */}
            <p><strong>State:</strong> <span className={getTextClass()}>{item.State}</span></p>
            <p><strong>Creation Date:</strong> {studyDetails ? formatDate(item.CreationTime) : 'Loading...'}</p>
            <p><strong>Patient ID:</strong> {studyDetails ? studyDetails.PatientID : 'Loading...'}</p>
            <p><strong>Patient Name:</strong> {studyDetails ? studyDetails.PatientName : 'Loading...'}</p>
            <p><strong>Study Date:</strong> {studyDetails ? formatDate(studyDetails.StudyDate) : 'Loading...'}</p>
            <p><strong>AE Title:</strong> {studyDetails ? item.Content.RemoteAet : 'Loading...'}</p>

            {item.State === 'Failure' ? (
                <div>
                    <p><strong>Error:</strong> {item.ErrorDescription} (Code: {item.ErrorCode})</p>
                </div>
            ) : (
                <div>
                    <p><strong>Progress:</strong> {item.Progress}%</p>
                </div>
            )}
            {item.State === 'Running' && (
                <div className="progress-bar" style={{ width: progressWidth }}></div>
            )}
        </div>
    );
};

const UploadHistory = ({ token }) => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasRunningJobs, setHasRunningJobs] = useState(false);

    const fetchJobs = () => {
        const session_url = `${process.env.REACT_APP_BACKEND_PHP_URL}/job_details.php`;
        const config = {
            method: "post",
            url: session_url,
            data: {
                sid: token,
            },
            withCredentials: true
        };
        axios.defaults.withCredentials = true;
        axios(config)
            .then(res => {
                const filteredJobs = res.data.filter(item => item.Type === "DicomModalityStore").reverse();
                setJobs(filteredJobs);
                const runningJobs = filteredJobs.some(job => job.State === 'Running');
                setHasRunningJobs(runningJobs);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching jobs:', error);
                setLoading(false); // Handle error gracefully
            });
    };

    useEffect(() => {
        fetchJobs();
        const interval = setInterval(() => {
            if (hasRunningJobs) {
                fetchJobs();
            } else {
                clearInterval(interval);
            }
        }, 10000);

        return () => clearInterval(interval);
    }, [token, hasRunningJobs]);

    if (loading) return <div>Loading...</div>;

    return (
        <div>
            <h2>Dicom Send History</h2>
            {jobs.map(item => (
                <ProgressItem key={item.ID} item={item} token={token} />
            ))}
        </div>
    );
};

export default UploadHistory;