import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import "./login.css";

const ResetPassviamail = () => {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [msg, setMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [alertType, setAlertType] = useState("success");

  const ResetPassviamail = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_NODE_URL}/resetpass`,
        {
          token: token,
          newpassword: newPassword,
        }
      );

      // console.log(response.data);
      setMsg(response.data.message);
      setAlertType("success");
      setOpen(true);
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data);
        setAlertType("error");
        setOpen(true);
      }
    }
  };

  return (
    <div
      style={{
        height: "100%",
        position: "absolute",
        left: "0px",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <div className="background">
        <Box sx={{ padding: 2 }}>
          <img
            src={process.env.PUBLIC_URL + "/assets/logo.png"}
            alt="Logo"
            width="200"
            height="auto"
          ></img>
        </Box>
        <Box
          height="100vh"
          overflow="hidden"
          display="flex"
          // flexDirection="row"
          justifyContent="flex-end"
          // backgroundColor="#202945"
        >
          <div
            // className="root"
            style={{
              padding: 10,
              paddingRight: 60,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <Container component="main" maxWidth="xs">
                <Paper
                  elevation={3}
                  sx={{
                    padding: 2,
                    paddingTop: 0,
                    backgroundColor: "#202940",
                    borderRadius: 1.5,
                    justifyContent: "flex-end",
                  }}
                >
                  <Box
                    sx={{
                      paddingTop: 2,
                      marginTop: 8,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography component="h2" variant="h5">
                      Reset Password
                    </Typography>
                    <Box
                      component="form"
                      onSubmit={ResetPassviamail}
                      noValidate
                      sx={{ mt: 1 }}
                    >
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="New Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, background: "#0290A2" }}
                      >
                        Reset
                      </Button>
                      <Grid container>
                        <Grid item xs={12}>
                          <Collapse in={open}>
                            <Fade
                              in={open}
                              timeout={{ enter: 1000, exit: 1000 }}
                              addEndListener={() => {
                                setTimeout(() => {
                                  setOpen(false);
                                }, 2000);
                              }}
                            >
                              <Alert
                                sx={{ mb: 2 }}
                                variant="filled"
                                severity={alertType}
                              >
                                {msg}
                              </Alert>
                            </Fade>
                          </Collapse>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Paper>
              </Container>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default ResetPassviamail;
