import React, { useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import BackdropIcon from "../BackdropIcon";
import Checkbox from "@mui/material/Checkbox";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormControlLabel from "@mui/material/FormControlLabel";

const MailButton = (props) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [mailRecipient, setMailRecipient] = useState("");
  const [message, setMessage] = useState("");
  const [openError, setOpenError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [sendingMail, setSendingMail] = useState(false);
  const [expireLink, setExpireLink] = useState(false);
  const [expiryDate, setExpiryDate] = useState(null);

  const handleDateChange = (newDate) => {
    if (newDate) {
      const utcDate = new Date(
        Date.UTC(newDate.year(), newDate.month(), newDate.date(), 0, 0, 0)
      );
      setExpiryDate(utcDate);
    }
  };

  const sendMail = async () => {
    let session_url;
    expireLink
      ? (session_url = `${process.env.REACT_APP_BACKEND_PHP_URL}/expiringstudymail.php`)
      : (session_url = `${process.env.REACT_APP_BACKEND_PHP_URL}/studymail.php`);
    setSendingMail(true);

    var config = {
      method: "post",
      url: session_url,
      data: {
        studyid: props.id,
        toemail: mailRecipient,
        bodyemail: message,
        sid: props.token,
        expiringdate: expiryDate,
      },
    };
    const res = await axios(config);
    // console.log(res.data);
    if ((res.data = 1)) {
      setErrorText("Your mail has been sent successfuly");
      setSendingMail(false);
    } else {
      setErrorText("Something went wrong");
      setSendingMail(false);
    }

    setOpenError(true);
  };
  if (sendingMail) {
    return <BackdropIcon content={<SendIcon />} />;
  }

  return (
    <>
      <Button
        sx={{
          background: "#80848c",
          fontSize: "11px",
          ":hover": {
            bgcolor: "#0290A2",
            color: "black",
          },
        }}
        onClick={handleClickOpen}
        variant="contained"
        endIcon={<SendIcon />}
      >
        Email
      </Button>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Email the Study</DialogTitle>
        <Divider />

        <DialogContent>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "45ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              label="To"
              type="email"
              placeholder="Email"
              required
              onChange={(e) => setMailRecipient(e.target.value)}
            />
            <br />
            <TextField
              id="outlined-multiline-flexible"
              label="Message"
              multiline
              onChange={(e) => setMessage(e.target.value)}
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={expireLink}
                  onChange={(e) => setExpireLink(e.target.checked)}
                  name="expireLink"
                  color="primary"
                />
              }
              label="Expire Link After"
            />
            {expireLink && (
              <>
                <br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    label="Expiry Date"
                    value={expiryDate}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </>
            )}
          </Box>
          <Grid style={{ height: "100%" }} item xs={12}>
            <Collapse in={openError}>
              <Fade
                in={openError}
                timeout={{ enter: 1000, exit: 1000 }}
                addEndListener={() => {
                  setTimeout(() => {
                    setOpenError(false);
                    setOpen(false);
                  }, 2000);
                }}
              >
                <Alert sx={{ mb: 2 }} variant="filled" severity="info">
                  {errorText}
                </Alert>
              </Fade>
            </Collapse>
          </Grid>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" onClick={sendMail} autoFocus>
              Send
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default MailButton;
