import React, { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import Grid from "@mui/system/Unstable_Grid";
import axios from "axios";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function ModalityDetails(props) {
    const [config, setConfig] = useState([""]);
    useEffect(() => {
        get_config();
    }, []);
    const get_config = async () => {
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BACKEND_PHP_URL}/config_settings.php?s=${props.token}`
            );
            setConfig(res.data);
            // console.log(res.data);
        } catch (error) {
            if (error.response) {
                console.log(error);
            }
        }
    };
    return (
        <Grid container spacing={2} marginBottom="0.5px">
            <Grid xs={12}>
                <Alert icon={false} severity="info" sx={{ backgroundColor: "#80848c" }}>
                    <Typography variant="h7" color={"black"}>
                        The Server Name is used only as an identifier. The AE Title will always be 16 characters or less, and can only include letters and numbers. <br />
                        The AE Title is the identifier used by the receiving machine if it is enabled. Make sure the IP Address and port is open.<br />
                        AE Title of this machine is:{config.ae_title}


                    </Typography>
                </Alert>
            </Grid>

        </Grid>
    );
}
