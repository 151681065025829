import React, { useState, useReducer } from "react";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const DeleteModality = (props) => {
  const [openDelete, setOpenDelete] = useState(false);
  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const handleDelete = async (id) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_PHP_URL}/deletemodality.php`,
        {
          sid: props.token,
          modality: props.id,
          name: props.name,
        }
      );
      //   setErrorText(res.data);
      // console.log(res.data);
      props.onDelete();
    } catch (error) {
      if (error.response) {
        // setErrorText(error.response.data.msg);
      }
    }
  };
  return (
    <>
      <IconButton
        style={{ color: "white" }}
        onClick={() => handleClickOpenDelete(props.id)}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
      <Dialog open={openDelete} onClose={handleCloseDelete}>
        <DialogTitle>Are you sure to delete this {props.name} modality?</DialogTitle>
        <DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDelete} autoFocus>
              Cancel
            </Button>
            <Button
              onClick={() => handleDelete(props.id)}
              style={{ color: "white", backgroundColor: "red" }}
            >
              Delete
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default DeleteModality;
