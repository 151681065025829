import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Fade from "@mui/material/Fade";
import ForgotPassword from "./buttons/ForgotPassword";
import Paper from "@mui/material/Paper";
import "./login.css";
import { makeStyles } from "@mui/styles";
import Footer from "./footer";

const UserLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState("");
  const history = useHistory();
  const [open, setOpen] = useState(false);

  //color for the input boxes
  const focusedColor = "#b5b9cb";
  const useStyles = makeStyles({
    root: {
      "& label.Mui-focused": {
        color: focusedColor,
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: focusedColor,
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#313649",
      },
    },
  });
  const classes = useStyles();

  const Auth = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_NODE_URL}/login`, {
        email: email,
        password: password,
      });
      history.push("/dashboard");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
        setOpen(true);
      }
    }
  };

  return (
    <div
      style={{
        height: "100%",
        position: "absolute",
        left: "0px",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Footer />
      <div className="background">
        <Box sx={{ padding: 2 }}>
          <img
            src={process.env.PUBLIC_URL + "/assets/logo.png"}
            alt="Logo"
            width="200"
            height="auto"
          ></img>
        </Box>
        <Box
          height="100vh"
          overflow="hidden"
          display="flex"
          // flexDirection="row"
          justifyContent="flex-end"
          // backgroundColor="#202945"
        >
          <div
            // className="root"
            style={{
              padding: 10,
              paddingRight: 60,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <Container component="main" maxWidth="xs">
                <Paper
                  elevation={3}
                  sx={{
                    padding: 2,
                    paddingTop: 0,
                    backgroundColor: "#202940",
                    borderRadius: 1.5,
                    justifyContent: "flex-end",
                  }}
                >
                  <Box
                    sx={{
                      paddingTop: 2,
                      marginTop: 8,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography component="h1" variant="h5">
                      Sign in
                    </Typography>
                    <Box component="form" onSubmit={Auth} sx={{ mt: 1 }}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        type="email"
                        id="email"
                        label="Username"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={classes.root}
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className={classes.root}
                      />
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, background: "#0290A2" }}
                      >
                        Sign In
                      </Button>
                      <Grid container>
                        <Grid item xs={12}>
                          <Collapse in={open}>
                            <Fade
                              in={open}
                              timeout={{ enter: 1000, exit: 1000 }}
                              addEndListener={() => {
                                setTimeout(() => {
                                  setOpen(false);
                                }, 2000);
                              }}
                            >
                              <Alert
                                sx={{ mb: 2 }}
                                variant="filled"
                                severity="error"
                              >
                                {msg}
                              </Alert>
                            </Fade>
                          </Collapse>
                        </Grid>
                        <Grid item xs align="right">
                          <ForgotPassword />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Paper>
              </Container>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default UserLogin;
