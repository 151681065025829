import React, { useState, useReducer, useEffect } from "react";
import {
  DayPilotCalendar,
  DayPilotNavigator,
} from "@daypilot/daypilot-lite-react";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import axios from "axios";
import SchedulerEvent from "./buttons/SchedulerEvent";
import { Typography } from "@mui/material";

const initState = {
  patientName: "",
  patientId: "",
  ownerName: "",
  accessionNumber: "",
  patientDob: "",
  patientSex: "",
  patientSpecies: "",
  patientBreed: "",
  procedureId: "",
  procedureDesc: "",
};

const reducer = (state, action) => {
  if (action.type === "reset") {
    return initState;
  }
  const result = { ...state };
  result[action.type] = action.value;
  return result;
};

const Schedules = (props) => {
  //clear up hooks and other things
  const [date, setDate] = useState(new Date().toJSON());
  const [dateToday, setDateToday] = useState(new Date() + 1);
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [state, dispatch] = useReducer(reducer, initState);
  const [startTime, setStartTime] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [endTime, setEndTime] = useState("");
  const [midList, setMidList] = useState([""]);
  const [eventData, setEventData] = useState("");
  const [eventDataOfSchedule, setEventDataOfSchedule] = useState("");
  const {
    patientName,
    patientId,
    ownerName,
    accessionNumber,
    patientDob,
    patientSex,
    patientSpecies,
    patientBreed,
    procedureId,
    procedureDesc,
  } = state;
  const [selectedModalityForSearch, setSelectedModalityForSearch] =
    useState(" ");
  const [selectedModalityForAdd, setSelectedModalityForAdd] = useState("");
  useEffect(() => {
    getmid();
    getEvents();
  }, [date, selectedModalityForSearch]);

  // useEffect(() => {
  //   // call api or anything
  //   getDate();
  // });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const eventModified = async (event) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_PHP_URL}/backend_modify.php`,
        {
          sid: props.token,
          id: event.e.data.id,
          start: event.newStart.toString(),
          end: event.newEnd.toString(),
        }
      );
      // console.log(res.data);
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      }
    }
  };
  const deleteEvent = async (id) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_PHP_URL}/backend_delete.php`,
        {
          sid: props.token,
          id: id,
        }
      );
      // console.log(res.data);
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      }
    }
  };

  // const getDate = async () => {
  //   try {
  //     const res = await axios.post(
  //       `${process.env.REACT_APP_BACKEND_PHP_URL}/timezone.php`,
  //       {
  //         sid: props.token,
  //       }
  //     );
  //     // console.log(res.data);
  //     setTimeZone(res.data);
  //   } catch (error) {
  //     if (error.response) {
  //       console.log(error.response);
  //     }
  //   }
  // };
  // console.log(new Date().toLocaleString("en-IN", { timeZone: "IST" }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_PHP_URL}/backend_create.php`,
        {
          sid: props.token,
          mid: selectedModalityForAdd,
          pname: patientName,
          pid: patientId,
          oname: ownerName,
          anumber: accessionNumber,
          pdob: patientDob,
          psex: patientSex,
          pspecies: patientSpecies,
          pbreed: patientBreed,
          prid: procedureId,
          prdescr: procedureDesc,
          start: startTime,
          end: endTime,
        }
      );
      // console.log(res.data);
      setOpenDialog(false);
      getEvents();
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      }
    }
    // submit modal

    dispatch({ type: "reset" });
  };

  const getEvents = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_PHP_URL}/backend_events.php?s=${props.token}&start=${date}&end=${dateToday}&m=${selectedModalityForSearch}`
      );
      setEventData(res.data);

      if (res.data === null) {
        setEventData([]);
      }
    } catch (error) {
      if (error.response) {
        console.log(error);
      }
    }
  };
  const getmid = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_PHP_URL}/modalitylist.php?s=${props.token}`
      );
      setMidList(res.data);
    } catch (error) {
      if (error.response) {
        console.log(error);
      }
    }
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: name, value });
  };
  if (midList) {
    return (
      <>
        <Stack direction="row" spacing={2}>
          <Paper>
            <TextField
              name="selectedModality"
              margin="dense"
              id="modalityType"
              label="Modality Type"
              type="text"
              select
              defaultValue={0}
              value={selectedModalityForSearch}
              sx={{ width: "20ch" }}
              onChange={(e) => setSelectedModalityForSearch(e.target.value)}
            >
              <MenuItem value={" "}>All</MenuItem>
              {midList.map(({ name, id }, index) => (
                <MenuItem key={index} value={id}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
            <DayPilotNavigator
              selectMode={"Day"}
              showMonths={3}
              skipMonths={3}
              onTimeRangeSelected={(e) => {
                setDate(e.day.value);
              }}
            />
          </Paper>
          <Paper>
            <DayPilotCalendar
              cellHeight="50"
              viewType="Day"
              startDate={date}
              onTimeRangeSelected={async (e) => {
                setStartTime(e.start.toString());
                setEndTime(e.end.toString());
                setOpenDialog(true);
              }}
              eventDeleteHandling="Update"
              onEventClicked={(event) => {
                setEventDataOfSchedule(event.e.data);
                handleClickOpen();
              }}
              businessBeginsHour={11}
              events={eventData}
              onEventDelete={async (event) => {
                // eslint-disable-next-line no-restricted-globals
                if (!confirm("Do you really want to delete this event?")) {
                  event.preventDefault();
                }
              }}
              onEventDeleted={(event) => {
                deleteEvent(event.e.data.id);
              }}
              onEventMoved={(event) => {
                eventModified(event);
              }}
              onEventResized={(event) => {
                eventModified(event);
              }}
            />
          </Paper>
        </Stack>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Schedule New Study</DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <TextField
                name="selectedModalityCreate"
                margin="dense"
                id="modalityTypeCreate"
                label="Modality"
                type="text"
                select
                value={selectedModalityForAdd}
                sx={{ m: 1, width: "14ch" }}
                onChange={(e) => setSelectedModalityForAdd(e.target.value)}
                required
              >
                {midList.map(({ name, id }, index) => (
                  <MenuItem key={index} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
              <Box
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "33ch" },
                }}
                autoComplete="off"
              >
                <TextField
                  name="patientName"
                  id="patientName"
                  label="Patient Name"
                  type="text"
                  onChange={onChange}
                  value={patientName}
                  required
                />
                <TextField
                  name="patientId"
                  id="patientId"
                  label="Patient ID"
                  type="text"
                  onChange={onChange}
                  value={patientId}
                />
                <TextField
                  name="ownerName"
                  id="ownerName"
                  label="Owner Name"
                  type="text"
                  onChange={onChange}
                  value={ownerName}
                  required
                />
                <TextField
                  name="accessionNumber"
                  id="accessionNumber"
                  label="Accession Number"
                  type="text"
                  onChange={onChange}
                  value={accessionNumber}
                />
                <TextField
                  name="patientDob"
                  id="patientDob"
                  label="Patient DOB"
                  type="text"
                  onChange={onChange}
                  value={patientDob}
                />
                <TextField
                  name="patientSex"
                  id="patientSex"
                  label="Patient Sex"
                  type="text"
                  onChange={onChange}
                  value={patientSex}
                />
                <TextField
                  name="patientSpecies"
                  id="patientSpecies"
                  label="Patient Species"
                  type="text"
                  onChange={onChange}
                  value={patientSpecies}
                />
                <TextField
                  name="patientBreed"
                  id="patientBreed"
                  label="Patient Breed"
                  type="text"
                  onChange={onChange}
                  value={patientBreed}
                />
                <TextField
                  name="procedureId"
                  id="procedureId"
                  label="Procedure ID"
                  type="text"
                  onChange={onChange}
                  value={procedureId}
                />
                <TextField
                  name="procedureDesc"
                  id="procedureDesc"
                  label="Procedure Description"
                  type="text"
                  onChange={onChange}
                  value={procedureDesc}
                />
              </Box>
              <DialogActions>
                <Button type="submit">Add</Button>
                <Button onClick={() => setOpenDialog(false)}>Close</Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
        <SchedulerEvent
          open={open}
          handleClose={() => handleClose()}
          data={eventDataOfSchedule}
        />
      </>
    );
  } else
    return (
      <Typography>
        Modality List is empty, please add atleast one modality from the
        modality tab
      </Typography>
    );
};
export default Schedules;
