

import React, { useState, useReducer } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Fade from "@mui/material/Fade";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";

const EditDicomServer = (props) => {
    //clearup later with array,

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BACKEND_PHP_URL}/adddicomserver.php`,
                {
                    sid: props.token,
                    serverName: modalityName,
                    aetitle: aetitle,
                    ipaddress: ipaddress,
                    serverport: serverport,
                    localaet: localaet,
                }
            );
            console.log(res.data);
            if (res.data === "Success") {
                setErrorText("Success");
                setAlertSeverity("success");
            } else {
                setErrorText("Failed");
                setAlertSeverity("error");
            }
            setOpenError(true);
            props.onEdit();
        } catch (error) {
            if (error.response) {
                setErrorText(error.response.data.msg);
                setAlertSeverity("error");
            }
        }
        // submit modal
    };

    const [openDialog, setOpenDialog] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [modalityName, setModalityName] = useState(props.name);
    const [ipaddress, setIpaddress] = useState(props.ipaddress);
    const [aetitle, setAetitle] = useState(props.aetitle);
    const [serverport, setServerport] = useState(props.port);
    const [localaet, setLocalAet] = useState(props.localaet);
    const [errorText, setErrorText] = useState("");
    const [ipError, setIpError] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("info");


    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    return (
        <>
            <IconButton
                style={{ color: "white" }}
                onClick={() => handleClickOpenDialog()}
            >
                <EditIcon fontSize="small" />
            </IconButton>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Edit Server Details</DialogTitle>
                <DialogContent>
                    <TextField
                        name="modalityName"
                        margin="dense"
                        id="modalityName"
                        label="Server Name"
                        type="text"
                        value={modalityName}
                        fullWidth
                        disabled={true}
                    />
                    <TextField
                        name="aetitle"
                        margin="dense"
                        id="aetitle"
                        label="AE Title"
                        type="text"
                        fullWidth
                        value={aetitle}
                        onChange={(e) => setAetitle(e.target.value)}
                        inputProps={{ maxLength: 16 }}
                        required
                    />
                    <TextField
                        name="ipaddress"
                        margin="dense"
                        id="ipaddress"
                        label="IP Address"
                        type="text"
                        fullWidth
                        value={ipaddress}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (value === "127.0.0.1") {
                                setIpError("IP address cannot be 127.0.0.1");
                            } else {
                                setIpError("");
                            }
                            setIpaddress(value);
                        }}
                        required
                        error={!!ipError}
                        helperText={ipError}
                    />

                    <TextField
                        name="serverport"
                        margin="dense"
                        id="serverport"
                        label="Port"
                        type="text"
                        fullWidth
                        value={serverport}
                        onChange={(e) => setServerport(e.target.value)}
                        required
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}

                    />
                    <TextField
                        name="localaet"
                        margin="dense"
                        id="localaet"
                        label="Local AE Title"
                        type="text"
                        fullWidth
                        value={localaet}
                        onChange={(e) => setLocalAet(e.target.value)}
                        inputProps={{ maxLength: 16 }}

                    />
                    <br />
                    <Collapse in={openError}>
                        <Fade
                            in={openError}
                            timeout={{ enter: 1000, exit: 1000 }}
                            addEndListener={() => {
                                setTimeout(() => {
                                    setOpenError(false);
                                    setOpenDialog(false);
                                }, 2000);
                            }}
                        >
                            <Alert sx={{ mb: 2 }} variant="filled" severity={alertSeverity}>
                                {errorText}
                            </Alert>
                        </Fade>
                    </Collapse>
                    <DialogActions>
                        <Button onClick={handleSubmit}>Update</Button>
                        <Button onClick={handleCloseDialog}>Close</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    );
};
export default EditDicomServer;
