import React from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";

const DeleteStudy = (props) => {
  const [open, setOpen] = React.useState(false);
  // const [alertVisibility, setAlertVisibility] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const studyDelete = async (studyId) => {
    // const response = await axios.get(
    //   `${process.env.REACT_APP_BACKEND_NODE_URL}/tokn`,
    //   {
    //     withCredentials: true,
    //   }
    // );
    const session_url = `${process.env.REACT_APP_BACKEND_PHP_URL}/studydelete.php`;
    var config = {
      method: "post",
      url: session_url,
      data: {
        sid: props.token,
        id: studyId,
      },
    };
    axios.defaults.withCredentials = true;
    const res = await axios(config);
    // setAlertVisibility(true);

    if (!!res.data) {
      setOpen(false);
      props.func();
    }
  };

  return (
    <>
      <Button
        endIcon={<DeleteIcon />}
        sx={{
          background: "#80848c",
          fontSize: "11px",
          ":hover": {
            bgcolor: "#CE4A3F",
            color: "black",
          },
        }}
        variant="contained"
        onClick={handleClickOpen}
      >
        Delete Study
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <Typography>This will permanently delete this Study.</Typography>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={() => studyDelete(props.id)}
              style={{ color: "white", backgroundColor: "red" }}
            >
              Delete
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default DeleteStudy;
