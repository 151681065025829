import React, { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddModalities from "./buttons/AddModalities";
import DeleteModality from "./buttons/DeleteModality";
import EditModality from "./buttons/EditModality";
import ModalityDetails from "./alerts/ModalityDetails";

const ModalityIncomming = (props) => {
    useEffect(() => {
        getModalities();
    }, []);

    const [modalities, setModalities] = useState([]);

    const getModalities = async () => {
        const session_url = `${process.env.REACT_APP_BACKEND_PHP_URL}/modalitysearch.php`;
        var config = {
            method: "post",
            url: session_url,
            data: {
                sid: props.token,
            },
        };
        axios.defaults.withCredentials = true;
        const res = await axios(config);
        setModalities(res.data);
    };

    if (modalities) {
        return (
            <>
                <AddModalities token={props.token} onAdd={getModalities} />
                <>
                    <ModalityDetails token={props.token} />
                    <div>
                        <TableContainer component={Paper}>
                            <Table

                                aria-label="simple table"
                                size="small"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Sl no</TableCell>
                                        <TableCell align="right">Modality Name</TableCell>
                                        <TableCell align="right">Modality Type</TableCell>
                                        <TableCell align="right">Description</TableCell>
                                        <TableCell align="right">AE Title</TableCell>
                                        <TableCell align="right">Default Study Time</TableCell>
                                        <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {modalities.map((modalities, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ border: 0 }}
                                            style={{ borderBottom: "none" }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                sx={{
                                                    borderColor: "#212121",
                                                    paddingTop: 1,
                                                    paddingBottom: 1,
                                                }}
                                            >
                                                {index + 1}
                                            </TableCell>
                                            <TableCell align="right" sx={{ borderColor: "#212121" }}>
                                                {modalities.name}
                                            </TableCell>
                                            <TableCell align="right" sx={{ borderColor: "#212121" }}>
                                                {modalities.ty}
                                            </TableCell>
                                            <TableCell align="right" sx={{ borderColor: "#212121" }}>
                                                {modalities.description}
                                            </TableCell>
                                            <TableCell align="right" sx={{ borderColor: "#212121" }}>
                                                {modalities.AET}
                                            </TableCell>
                                            <TableCell align="right" sx={{ borderColor: "#212121" }}>
                                                {modalities.pro_time}
                                            </TableCell>
                                            <TableCell align="right" sx={{ borderColor: "#212121" }}>
                                                <EditModality
                                                    id={modalities.id}
                                                    name={modalities.name}
                                                    type={modalities.ty}
                                                    description={modalities.description}
                                                    pro_time={modalities.pro_time}
                                                    token={props.token}
                                                    aetitle={modalities.AET}
                                                    onEdit={getModalities}
                                                />
                                                <DeleteModality
                                                    id={modalities.id}
                                                    token={props.token}
                                                    name={modalities.name}
                                                    onDelete={getModalities}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <></>
                </>
            </>
        );
    } else return <AddModalities token={props.token} onAdd={getModalities} />;
};
export default ModalityIncomming;
