import React, { useState, useReducer } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Fade from "@mui/material/Fade";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MenuItem from "@mui/material/MenuItem";
// import modalitiesList from "../modalitiesList";

const initState = {
  modalityName: "",
  selectedModality: "",
  description: "",
  studyTime: "",
  aetitle: "",
};

const reducer = (state, action) => {
  if (action.type === "reset") {
    return initState;
  }
  const result = { ...state };
  result[action.type] = action.value;
  return result;
};

const AddModalities = (props) => {
  //clearup later with array,
  const [state, dispatch] = useReducer(reducer, initState);
  const { modalityName, selectedModality, description, studyTime, aetitle } = state;
  const [modalities, setModalities] = useState([]);
  const [modalityNameError, setModalityNameError] = useState('')
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_PHP_URL}/addmodality.php`,
        {
          sid: props.token,
          mname: modalityName,
          mtype: selectedModality,
          mdesc: description,
          mtime: studyTime,
          aetitle: aetitle,
        }
      );
      if (res.data === "success") {
        setErrorText("Successfully added the modality");
      } else if (res.data === "exist") {
        setErrorText("Modality with the name already exist");
      } else {
        setErrorText("Something went wrong");
      }

      setOpenError(true);
      props.onAdd();
    } catch (error) {
      if (error.response) {
        setErrorText(error.response.data.msg);
      }
    }
    // submit modal

    dispatch({ type: "reset" });
  };
  const onChange = (e) => {
    const { name, value } = e.target;

    if (name === "modalityName") {
      // Regular expression to allow only letters, numbers, hyphens, and underscores
      const regex = /^[a-zA-Z0-9-_]+$/;

      if (!regex.test(value)) {
        setModalityNameError('Modality name can only contain letters, numbers, hyphens, and underscores.');
      } else {
        setModalityNameError('');
      }
    }
    dispatch({ type: name, value });
  };
  const [openDialog, setOpenDialog] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const handleClickOpenDialog = () => {
    fetchModalities()
      .then(data => setModalities(data))
      .catch(error => console.error('Error fetching modalities:', error));
    setOpenDialog(true);
  };
  const fetchModalities = () => {
    return axios.get(`${process.env.REACT_APP_BACKEND_PHP_URL}/modalityNamePair.php`)
      .then(response => response.data)
      .catch(error => {
        throw error; // Propagate error
      });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  return (
    <>
      <Button
        color="primary"
        component="span"
        onClick={handleClickOpenDialog}
        startIcon={<AddCircleIcon />}
      >
        Add Modality
      </Button>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Add New Modality</DialogTitle>
        <DialogContent>
          <TextField
            name="modalityName"
            margin="dense"
            id="modalityName"
            label="Modality Name"
            type="text"
            value={modalityName}
            fullWidth
            onChange={onChange}
            required
            error={!!modalityNameError}
            helperText={modalityNameError}
          />

          <TextField
            name="selectedModality"
            margin="dense"
            id="modalityType"
            label="Modality Type"
            type="text"
            fullWidth
            select
            value={selectedModality}
            onChange={onChange}
          >
            {modalities.map((code, index) => (
              <MenuItem key={index} value={code.code}>
                {code.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            name="description"
            margin="dense"
            id="modalitydesc"
            label="Description"
            type="text"
            fullWidth
            value={description}
            onChange={onChange}
          />
          <TextField
            name="aetitle"
            margin="dense"
            id="aetitle"
            label="AE Title"
            type="text"
            fullWidth
            value={aetitle}
            onChange={onChange}
            inputProps={{ maxLength: 16 }}
            required
          />

          <TextField
            name="studyTime"
            margin="dense"
            id="studytime"
            label="Default Study Time"
            type="text"
            fullWidth
            value={studyTime}
            onChange={onChange}
          />
          <br />
          <Collapse in={openError}>
            <Fade
              in={openError}
              timeout={{ enter: 1000, exit: 1000 }}
              addEndListener={() => {
                setTimeout(() => {
                  setOpenError(false);
                  setOpenDialog(false);
                }, 2000);
              }}
            >
              <Alert sx={{ mb: 2 }} variant="filled" severity="info">
                {errorText}
              </Alert>
            </Fade>
          </Collapse>
          <DialogActions>
            <Button onClick={handleSubmit} disabled={!!modalityNameError}>Add</Button>
            <Button onClick={handleCloseDialog}>Close</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default AddModalities;
