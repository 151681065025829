import React, { useState, useReducer } from "react";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const DeleteDicomModality = (props) => {
    const [openDelete, setOpenDelete] = useState(false);
    const handleClickOpenDelete = () => {
        setOpenDelete(true);
    };
    const handleCloseDelete = () => {
        setOpenDelete(false);
    };
    const handleDelete = async (name) => {
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BACKEND_PHP_URL}/deletedicomserver.php`,
                {
                    sid: props.token,
                    serverName: props.name,
                }
            );
            //   setErrorText(res.data);
            // console.log(res.data);
            props.onDelete();
        } catch (error) {
            if (error.response) {
                // setErrorText(error.response.data.msg);
            }
        }
    };
    return (
        <>
            <IconButton
                style={{ color: "white" }}
                onClick={() => handleClickOpenDelete(props.name)}
            >
                <DeleteIcon fontSize="small" />
            </IconButton>
            <Dialog open={openDelete} onClose={handleCloseDelete}>
                <DialogTitle>Are you sure to delete this Server?</DialogTitle>
                <DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDelete} autoFocus>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => handleDelete(props.name)}
                            style={{ color: "white", backgroundColor: "red" }}
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    );
};
export default DeleteDicomModality;
