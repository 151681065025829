import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FileUpload from "../uploadFile";
import Button from "@mui/material/Button";
import FolderUpload from "../uploadFolder";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

const UploadDicom = (props) => {
  const [openUpload, setOpenUpload] = useState(false);
  const handleClickOpenUpload = () => {
    setOpenUpload(true);
  };
  const handleCloseUpload = () => {
    setOpenUpload(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        component="span"
        onClick={handleClickOpenUpload}
        sx={{ background: "#0290A2" }}
      >
        Upload
      </Button>
      <Dialog
        open={openUpload}
        onClose={handleCloseUpload}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Upload Study</DialogTitle>
        <DialogContent>
          <div>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <div>
                <FolderUpload token={props.token} close={handleCloseUpload} />
              </div>
              <div>
                <FileUpload
                  token={props.token}
                  close={handleCloseUpload}
                  refresh={() => props.func()}
                />
              </div>
            </Stack>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpload}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadDicom;
