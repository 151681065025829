import React, { useState, useEffect, useReducer } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import UserSettings from "./UserSettings";
import Studies from "./Studies";
import ErrorBoundary from "./ErrorBoundary";
import axios from "axios";
import jwt_decode from "jwt-decode";
import UserTableNew from "./UserTableNew";
import Modalities from "./Modalities";
import Schedules from "./Schedules";
import SimpleBackdrop from "./SimpleBackdrop";
import UploadDicom from "./buttons/UploadDicom";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import ScheduleIcon from "@mui/icons-material/Schedule";
import PeopleIcon from "@mui/icons-material/People";
import PeerConnection from "./PeerConnection";
import UploadHistory from "./UploadHistory";
import StorageIcon from '@mui/icons-material/Storage';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ModalityIncomming from "./ModalityIncomming";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [component, setComponent] = useState("Studies");
  const [isLoading, setIsLoading] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  //admin panel
  const [role, setRole] = useState("user");
  const [token, setToken] = useState("");
  const [isMounted, setIsMounted] = useState(true);
  const [trigger, setTrigger] = useState(0);

  useEffect(() => {
    if (isMounted) {
      refreshToken();
    }
  }, []);
  useEffect(() => {
    return () => {
      setToken(null);
      setIsMounted(false);
    };
  }, []);
  const refreshStudy = () => {
    setTrigger((trigger) => trigger + 1);
  };

  const getAdvancedOptions = async () => {
    const session_url = `${process.env.REACT_APP_BACKEND_PHP_URL}/advanced_options.php`;
    var config = {
      method: "post",
      url: session_url,
      data: {
        sid: token,
      },
    };

    axios.defaults.withCredentials = true;

    try {
      const res = await axios(config);
      // console.log(res.data);
      const buttonValue = res.data && res.data['3d_button'] ? res.data['3d_button'] : '0';
      sessionStorage.setItem('3d', buttonValue);

    } catch (error) {
      console.error('Error fetching advanced options:', error);
    }
  };
  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_NODE_URL}/token`,
        {
          withCredentials: true,
        }
      );
      setToken(response.data.refreshToken);
      setIsLoading(false);
      const decoded = jwt_decode(response.data.accessToken);
      setRole(decoded.role);
      //   setEmail(decoded.email);
    } catch (error) {
      if (error.response) {
        console.log(error);
        setIsLoading(false);
      }
    }
  };
  if (isLoading) {
    return <SimpleBackdrop />;
  } else
    getAdvancedOptions();
  return (
    <ErrorBoundary>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          sx={{ backgroundColor: "#1A2035", color: "#b5b9cb" }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                mr: 2,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component="div"
              style={{ flex: 1 }}
            >
              {component}
            </Typography>

            <div>
              <UserSettings />
            </div>
          </Toolbar>
        </AppBar>

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader component="div">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/assets/logo.png"}
                alt="Logo"
                className="drawer-logo"
                style={{ width: "85%" }}
              />
            </div>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>

          <Divider />

          <List>
            <ListItem
              disablePadding
              onClick={() => setComponent("Studies") & refreshToken()}
            >
              <ListItemButton>
                <ListItemIcon>
                  <FileCopyIcon style={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Studies"
                  primaryTypographyProps={{ fontSize: "18px" }}
                />
              </ListItemButton>
            </ListItem>
            {role === "admin" ? (
              <ListItem
                disablePadding
                onClick={() => setComponent("Modalities")}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <DevicesOtherIcon style={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Modalities"
                    primaryTypographyProps={{ fontSize: "18px" }}
                  />
                </ListItemButton>
              </ListItem>
            ) : (
              <Typography></Typography>
            )}
            <ListItem disablePadding onClick={() => setComponent("Schedule")}>
              <ListItemButton>
                <ListItemIcon>
                  <ScheduleIcon style={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Schedule"
                  primaryTypographyProps={{ fontSize: "18px" }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding onClick={() => setComponent("Dicom Servers")}>
              <ListItemButton>
                <ListItemIcon>
                  <StorageIcon style={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Dicom Servers"
                  primaryTypographyProps={{ fontSize: "18px" }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding onClick={() => setComponent("Tasks")}>
              <ListItemButton>
                <ListItemIcon>
                  <AssignmentIcon style={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Tasks"
                  primaryTypographyProps={{ fontSize: "18px" }}
                />
              </ListItemButton>
            </ListItem>
            {role === "admin" ? (
              <ListItem disablePadding onClick={() => setComponent("Users")}>
                <ListItemButton>
                  <ListItemIcon>
                    <PeopleIcon style={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Users"
                    primaryTypographyProps={{ fontSize: "18px" }}
                  />
                </ListItemButton>
              </ListItem>
            ) : (
              <Typography></Typography>
            )}
            <Divider />
            <ListItem disablePadding>
              <ListItemButton>
                <UploadDicom token={token} func={() => refreshStudy()} />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          {component === "Studies" ? (
            <Studies token={token} trigger={trigger} />
          ) : component === "Modalities" ? (
            <ModalityIncomming token={token} />
          ) : component === "Users" ? (
            <UserTableNew />
          ) : component === "Schedule" ? (
            <Schedules token={token} />
          ) : component === "Dicom Servers" ? (
            <PeerConnection token={token} />
          ) :
            component === "Tasks" ? (
              <UploadHistory token={token} />

            ) :
              (
                <Typography>123</Typography>
              )}
        </Main>
      </Box>
    </ErrorBoundary>
  );
}
