import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";

const ForgotPassword = () => {
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [email, setEmail] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const sendResetMail = async () => {
    if (email === "") {
      setErrorText("Enter Email Address");
      setOpenError(true);
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_NODE_URL}/forgotpass`,
          {
            email,
          }
        );
        if (response.data === "rec email sent") {
          setErrorText("Recovery Mail is sent, check your inbox");
          setOpenError(true);
        }
      } catch (error) {
        if (error.response.data === "email not in records") {
          setErrorText("Make sure the email address is correct");
          setOpenError(true);
        }
      }
    }
  };
  return (
    <>
      <Button onClick={handleClickOpen} sx={{ color: "#b5b9cb" }}>
        Forgot password
      </Button>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>Reset Password</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item>
              <Typography>Please enter your email address</Typography>
              <TextField
                id="standard-basic"
                label="Email"
                variant="standard"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid style={{ height: "100%" }} item xs={12}>
              <Collapse in={openError}>
                <Fade
                  in={openError}
                  timeout={{ enter: 1000, exit: 1000 }}
                  addEndListener={() => {
                    setTimeout(() => {
                      setOpenError(false);
                      setOpen(false);
                    }, 2000);
                  }}
                >
                  <Alert sx={{ mb: 2 }} variant="filled">
                    {errorText}
                  </Alert>
                </Fade>
              </Collapse>
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            <Button onClick={sendResetMail} autoFocus>
              Reset
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ForgotPassword;
