import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import AddUserButton from "./AddUserButton";
import "./style.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const UserTableNew = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState(0);
  const [id, setId] = useState(0);
  const [openEditor, setOpenEditor] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [roleN, setRoleN] = useState("user");
  const [openDelete, setOpenDelete] = useState(false);

  const handleClickOpenDelete = (rowData) => {
    setOpenDelete(true);
    setUserId(rowData.id);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const handleChange = (event) => {
    setRoleN(event.target.value);
  };
  const handleClickOpeneditor = (rowData) => {
    setOpenEditor(true);
    setId(rowData.id);
    setEmail(rowData.email);
    setName(rowData.name);
    setRoleN(rowData.access_level);
  };
  const handleCloseEditor = () => {
    setOpenEditor(false);
  };
  useEffect(() => {
    getUsers();
  }, []);

  const axiosJWT = axios.create();
  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_NODE_URL}/token`,
          {
            withCredentials: true,
          }
        );
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const deleteUser = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_NODE_URL}/deleteuser`,
        {
          userId,
        }
      );
      if (response.data) {
        // console.log(response.data);
      }
    } catch (error) {
      if (error.response.data) {
        console.log("error");
      }
    }
    getUsers();
    setOpenDelete(false);
  };
  const getUsers = async () => {
    const response = await axiosJWT.get(
      `${process.env.REACT_APP_BACKEND_NODE_URL}/users`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setUsers(response.data);
    // console.log(response.data);
  };
  const editUser = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_NODE_URL}/editprofile`,
        {
          id,
          email,
          name,
          roleN,
          newPassword,
        }
      );
      if (response.data) {
        setErrorText(response.data);
        setOpenError(true);
      }
    } catch (error) {
      if (error.response.data) {
        console.log("error");
      }
    }
    getUsers();
  };
  return (
    <>
      <div>
        <AddUserButton onAdd={getUsers} />
        <TableContainer component={Paper}>
          <Table aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">Access Level</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((users) => (
                <TableRow
                  key={users.name}
                  sx={{ border: 0 }}
                  style={{ borderBottom: "none" }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      borderColor: "#212121",
                      paddingTop: 1,
                      paddingBottom: 1,
                    }}
                  >
                    {users.name}
                  </TableCell>
                  <TableCell align="right" sx={{ borderColor: "#212121" }}>
                    {users.email}
                  </TableCell>
                  <TableCell align="right" sx={{ borderColor: "#212121" }}>
                    {users.access_level}
                  </TableCell>
                  <TableCell align="right" sx={{ borderColor: "#212121" }}>
                    <IconButton
                      style={{ color: "white" }}
                      onClick={() => handleClickOpeneditor(users)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      style={{ color: "white" }}
                      onClick={() => handleClickOpenDelete(users)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <>
        <Dialog open={openDelete} onClose={handleCloseDelete}>
          <DialogTitle>Are you sure to delete this user?</DialogTitle>
          <DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDelete}>Cancel</Button>
              <Button
                onClick={() => deleteUser(id)}
                style={{ color: "white", backgroundColor: "red" }}
                variant="contained"
              >
                Delete
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
        <Dialog
          maxWidth="sm"
          fullWidth
          open={openEditor}
          onClose={handleCloseEditor}
        >
          <DialogTitle>Edit Profile</DialogTitle>
          <Divider />
          <DialogContent>
            <TextField
              disabled
              autoFocus
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              fullWidth
              value={email}
            />
            <TextField
              margin="dense"
              id="name"
              label="Edit Name"
              type="name"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              margin="dense"
              id="password"
              label="New Password"
              type="password"
              fullWidth
              placeholder="*****"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <br />
            <FormControl>
              <FormLabel id="perm-radio-buttons-group">Permission</FormLabel>
              <RadioGroup
                aria-labelledby="perm-buttons-group"
                name="perm-radio-buttons-group"
                value={roleN}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="user"
                  control={<Radio />}
                  label="User"
                />
                <FormControlLabel
                  value="admin"
                  control={<Radio />}
                  label="Admin"
                />
              </RadioGroup>
            </FormControl>
            <Grid style={{ height: "100%" }} item xs={12}>
              <Collapse in={openError}>
                <Fade
                  in={openError}
                  timeout={{ enter: 1000, exit: 1000 }}
                  addEndListener={() => {
                    setTimeout(() => {
                      setOpenError(false);
                    }, 2000);
                  }}
                >
                  <Alert sx={{ mb: 2 }} variant="filled">
                    {errorText}
                  </Alert>
                </Fade>
              </Collapse>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditor}>Close</Button>
            <Button onClick={editUser} autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </>
  );
};

export default UserTableNew;
