import { BrowserRouter, Route, Switch } from "react-router-dom";

import Login from "./components/Login";
import ResetPassviamail from "./components/resetPassviamail";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import UserDrawerNew from "./components/UserDrawerNew";
import ResponsiveDrawer from "./components/ResponsiveDrawer";





const darkTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#b5b9cb",
    },
    secondary: {
      main: "#1A2035",
    },
    background: {
      default: "#1A2035",
      paper: "#2C3448",
    },
    text: {
      primary: "#b5b9cb",
      secondary: "#9095A3",
      disabled: "#9095A3",
      hint: "#9095A3",
    },
    divider: "rgba(255,255,255,0.12)",
  },
  typography: {
    body2: {
      fontSize: 13,
    },
    body1: {
      fontSize: 14,
    },
    caption: {
      fontSize: 14,
    },
    button: {
      fontSize: 12,
    },
  },
  overrides: {
    MuiOutlinedInput: {
      input: {
        "&:-webkit-autofill": {
          "-webkit-box-shadow": "0 0 0 100px #000 inset",
          "-webkit-text-fill-color": "#000000",
        },
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#6b6b6b #2b2b2b",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#2b2b2b",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#6b6b6b",
            minHeight: 24,
            border: "3px solid #2b2b2b",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#2b2b2b",
          },
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <Route path="/reset/:token">
            <ResetPassviamail />
          </Route>
          <Route path="/dashboard">
            {/* <UserDrawerNew /> */}
            <ResponsiveDrawer/>
          </Route>
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
