import React, { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddModalities from "./buttons/AddModalities";
import DeleteDicomModality from "./buttons/DeleteDicomModality.js";
import DicomServerDetails from "./alerts/DicomServerDetails.js";
import EditDicomServer from "./buttons/EditDicomServer";
import AddDicomServer from "./buttons/AddDicomPeer";



const PeerConnection = (props) => {
    useEffect(() => {
        getServers();
    }, []);

    const [servers, setServers] = useState([]);

    const getServers = async () => {
        const session_url = `${process.env.REACT_APP_BACKEND_PHP_URL}/getmodalities.php`;
        var config = {
            method: "post",
            url: session_url,
            data: {
                sid: props.token,
            },
        };
        axios.defaults.withCredentials = true;
        const res = await axios(config);
        setServers(res.data);

    };

    if (servers) {
        const serverKeys = Object.keys(servers);
        return (
            <>
                <AddDicomServer token={props.token} onAdd={getServers} />
                <DicomServerDetails token={props.token} />

                <>

                    <div>
                        <TableContainer component={Paper} sx={{ width: '100%', overflowX: 'auto' }}>
                            <Table

                                aria-label="simple table"
                                size="small"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Sl no</TableCell>
                                        <TableCell align="right">Server Name</TableCell>
                                        <TableCell align="right">AE Title</TableCell>
                                        <TableCell align="right">IP Adress</TableCell>
                                        <TableCell align="right">Port</TableCell>
                                        <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {serverKeys
                                        .filter(serverKey => servers[serverKey].Host !== '127.0.0.1')
                                        .map((serverKey, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row" sx={{ borderColor: "#212121" }}>
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell align="right" sx={{ borderColor: "#212121" }}>{serverKey}</TableCell>
                                                <TableCell align="right" sx={{ borderColor: "#212121" }}>{servers[serverKey].AET}</TableCell>
                                                <TableCell align="right" sx={{ borderColor: "#212121" }}>{servers[serverKey].Host}</TableCell>
                                                <TableCell align="right" sx={{ borderColor: "#212121" }}>{servers[serverKey].Port}</TableCell>
                                                <TableCell align="right" sx={{ borderColor: "#212121" }}>
                                                    <EditDicomServer
                                                        // id={servers.id}
                                                        name={serverKey}
                                                        aetitle={servers[serverKey].AET}
                                                        ipaddress={servers[serverKey].Host}
                                                        port={servers[serverKey].Port}
                                                        localaet={servers[serverKey].LocalAet}
                                                        token={props.token}
                                                        onEdit={getServers}
                                                    />
                                                    <DeleteDicomModality
                                                        name={serverKey}
                                                        token={props.token}
                                                        onDelete={getServers}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <></>
                </>
            </>
        );
    } else return <AddDicomServer token={props.token} onAdd={getServers} />;
};
export default PeerConnection;
