import React from "react";
import { useState } from "react";
import axios from "axios";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import IconButton from "@mui/material/IconButton";
import BackdropIcon from "./BackdropIcon";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";

export default function UploadFile(props) {
  const [files, setFiles] = useState([""]);
  const [fileCount, setFileCount] = useState("");
  const [fileUploadProgress, setFileUploadProgress] = useState(false);
  const [fileUploadResponse, setFileUploadResponse] = useState(null);
  const uploadFileHandler = (event) => {
    setFiles(event.target.files);
    setFileCount(event.target.files.length);
  };

  const fileSubmitHandler = async (event) => {
    event.preventDefault();
    setFileUploadProgress(true);
    setFileUploadResponse(null);
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(`files[]`, files[i]);
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_PHP_URL}/studyupload.php?s=${props.token}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setFileUploadResponse(response.data);
    } catch (error) {
      if (error.response) {
        console.error("Error while uploading file!", error.response);
      }
    }
    setFileUploadProgress(false);
    setFileCount("");
    setFiles([""]);
    props.close();
    props.refresh();
  };
  if (fileUploadProgress) {
    return <BackdropIcon content={<CloudUploadOutlinedIcon />} />;
  } else
    return (
      <form onSubmit={fileSubmitHandler}>
        <input
          type="file"
          multiple
          id="icon-button-file"
          onChange={uploadFileHandler}
          style={{ display: "none" }}
          accept=".dcm,.zip"
        />
        <label htmlFor="icon-button-file">
          <IconButton
            color="primary"
            component="span"
            size="large"
            sx={{
              "& svg": {
                fontSize: 50,
              },
            }}
          >
            <UploadFileOutlinedIcon />
          </IconButton>
        </label>
        <br />
        {!fileCount && <div>Select File(s) to upload</div>}
        {fileCount && <div>Selected {fileCount} file(s) to upload</div>}
        <br />
        <Button
          type="submit"
          id="sub-file"
          endIcon={<FileUploadIcon />}
          sx={{
            background: "#80848c",
            ":hover": {
              bgcolor: "#0290A2",
            },
            color: "black",
            paddingLeft: 2,
          }}
        >
          Upload
        </Button>

        {fileUploadProgress && (
          <p style={{ color: "green" }}>Uploading File(s)</p>
        )}
      </form>
    );
}
