import React, { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import Grid from "@mui/system/Unstable_Grid";
import axios from "axios";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function ModalityDetails(props) {
  const [config, setConfig] = useState([""]);
  useEffect(() => {
    get_config();
  }, []);
  const get_config = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_PHP_URL}/config_settings.php?s=${props.token}`
      );
      setConfig(res.data);
      // console.log(res.data);
    } catch (error) {
      if (error.response) {
        console.log(error);
      }
    }
  };
  return (
    <Grid container spacing={2} marginBottom="0.5px">
      {/* <Grid xs={6}> */}
      {/* <Alert icon={false} severity="info" sx={{ backgroundColor: "#80848c" }}>
          <Typography variant="h7" color={"black"}>
            By default you can add upto 10 modalities. <br /> If you want to add
            more please contact support.
          </Typography>
        </Alert> */}
      {/* </Grid> */}
      <Grid xs={12}>
        <Alert icon={false} severity="info" sx={{ backgroundColor: "#80848c" }}>
          <Typography variant="h7" color={"black"}>
            CONFIGURATION SETTINGS FOR MODALITIES (DICOM SEND & WORKLIST)
            <br />
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="baseline"
              spacing={0}
            >
              <div>IP: {config.ip}</div> <div>PORT: {config.port}</div>
              <div>AE Title: {config.ae_title}</div>
            </Stack>
          </Typography>
        </Alert>
      </Grid>
    </Grid>
  );
}
