import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import PersonIcon from "@mui/icons-material/Person";
import jwt_decode from "jwt-decode";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider } from "@mui/material";
import TextField from "@mui/material/TextField";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";

const UserSettings = () => {
  const [openEditProfile, setOpenEditProfile] = useState(false);

  const handleClickEditProfile = () => {
    setOpenEditProfile(true);
  };

  const handleCloseEditProfile = () => {
    setOpenEditProfile(false);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [name, setName] = useState("");
  const [updatedName, setUpdatedName] = useState("");
  const [email, setEmail] = useState("");
  const [newpassword, setNewpassword] = useState("");
  const [msg, setMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [responseText, setResponseText] = useState("");

  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");
  const [role, setRole] = useState("");
  const history = useHistory();

  useEffect(() => {
    refreshToken();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_NODE_URL}/token`,
        {
          withCredentials: true,
        }
      );
      setToken(response.data.accessToken);
      const decoded = jwt_decode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
      setRole(decoded.role);
      setEmail(decoded.email);
    } catch (error) {
      if (error.response) {
        history.push("/");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_NODE_URL}/token`
        );
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
        setRole(decoded.role);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const Logout = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_NODE_URL}/logout`, {
        withCredentials: true,
      });

      history.push("/");
      window.location.reload(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const EditUser = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_NODE_URL}/edituser`,
        {
          name: updatedName,
          email: email,
          newpassword: newpassword,
        }
      );
      setResponseText(response.data);
      setOpen(true);
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data);
        // console.log(error.response.data);
      }
    }
  };
  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={openEditProfile}
        onClose={handleCloseEditProfile}
      >
        <DialogTitle>Edit Profile</DialogTitle>
        <Divider />
        <DialogContent>
          <TextField
            disabled
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={email}
          />
          <TextField
            margin="dense"
            id="name"
            label="New Name"
            type="name"
            fullWidth
            variant="standard"
            placeholder={name}
            onChange={(e) => setUpdatedName(e.target.value)}
          />
          <TextField
            margin="dense"
            id="name"
            label="New Password"
            type="password"
            fullWidth
            variant="standard"
            placeholder="*****"
            value={newpassword}
            onChange={(e) => setNewpassword(e.target.value)}
          />
          <Grid style={{ height: "100%" }} item xs={12}>
            <Collapse in={open}>
              <Fade
                in={open}
                timeout={{ enter: 1000, exit: 1000 }}
                addEndListener={() => {
                  setTimeout(() => {
                    setOpen(false);
                  }, 2000);
                }}
              >
                <Alert sx={{ mb: 2 }} variant="filled">
                  {responseText}
                </Alert>
              </Fade>
            </Collapse>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditProfile}>Cancel</Button>
          <Button onClick={EditUser} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ flexGrow: 1 }}>
        <Toolbar>
          <div>
            <Tooltip title="Open settings">
              <Button
                variant="text"
                size="medium"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                style={{ borderRadius: 0 }}
                label={name}
                startIcon={<PersonIcon />}
              >
                {name}
              </Button>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClickEditProfile}>Edit Profile</MenuItem>
              <MenuItem onClick={Logout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </Box>
    </>
  );
};

export default UserSettings;
