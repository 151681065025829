export default [
  { code: "US", name: "Ultrasound" },
  { code: "DX", name: "Digital Radiography" },
  { code: "CT", name: "Computed Tomography" },
  { code: "Es", name: "Endoscopy" },
  { code: "GM", name: "General Microscopy" },
  { code: "MG", name: "Mammography" },
  { code: "MR", name: "Magnetic Resonance" },
  { code: "PT", name: "Positron Emission Tomography (PET)" },
  { code: "RF", name: "Radio Fluoroscopy" },
  { code: "RG", name: "Radiographic imaging" },
];
