import React, { useState, useReducer } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Fade from "@mui/material/Fade";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const initState = {
    serverName: "",
    aetitle: "",
    ipaddress: "",
    serverport: "",
    localaet: "",
};

const reducer = (state, action) => {
    if (action.type === "reset") {
        return initState;
    }
    const result = { ...state };
    result[action.type] = action.value;
    return result;
};

const AddDicomPeer = (props) => {
    //clearup later with array,
    const [state, dispatch] = useReducer(reducer, initState);
    const { serverName, aetitle, ipaddress, serverport, localaet } = state;
    const [modalities, setModalities] = useState([]);
    const [ipError, setIpError] = useState("");
    const [serverNameError, setServerNameError] = useState('');
    const [alertSeverity, setAlertSeverity] = useState("info");
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BACKEND_PHP_URL}/adddicomserver.php`,
                {
                    sid: props.token,
                    serverName: serverName,
                    aetitle: aetitle,
                    ipaddress: ipaddress,
                    serverport: serverport,
                    localaet: localaet,
                }
            );
            console.log(res.data);
            if (res.data === "Success") {
                setErrorText("Success");
                setAlertSeverity("success");
            } else {
                setErrorText("Failed");
                setAlertSeverity("error");
            }
            setOpenError(true);
            props.onAdd();
        } catch (error) {
            if (error.response) {
                setErrorText(error.response.data.msg);
                setAlertSeverity("error");
            }
        }
        // submit modal
        dispatch({ type: "reset" });
    };
    const onChange = (e) => {
        const { name, value } = e.target;

        if (name === "ipaddress" && value === "127.0.0.1") {
            setIpError("IP address cannot be 127.0.0.1");
        } else {
            setIpError("");
        }

        if (name === "serverName") {
            // Regular expression to allow only letters, numbers, hyphens, and underscores
            const regex = /^[a-zA-Z0-9-_]+$/;

            if (!regex.test(value)) {
                setServerNameError('Server name can only contain letters, numbers, hyphens, and underscores.');
            } else {
                setServerNameError('');
            }
        }

        // Dispatch action for name and value changes
        dispatch({ type: name, value });
    };

    const [openDialog, setOpenDialog] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [errorText, setErrorText] = useState("");

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    return (
        <>
            <Button
                color="primary"
                component="span"
                onClick={handleClickOpenDialog}
                startIcon={<AddCircleIcon />}
            >
                Add New Server
            </Button>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Add New Dicom Server</DialogTitle>
                <DialogContent>
                    <TextField
                        name="serverName"
                        margin="dense"
                        id="serverName"
                        label="Server Name"
                        type="text"
                        value={serverName}
                        fullWidth
                        onChange={onChange}
                        required
                        error={!!serverNameError}
                        helperText={serverNameError}

                    />

                    <TextField
                        name="aetitle"
                        margin="dense"
                        id="aetitle"
                        label="AE Title"
                        type="text"
                        fullWidth
                        value={aetitle}
                        onChange={onChange}
                        inputProps={{ maxLength: 16 }}
                        required
                    />

                    <TextField
                        name="ipaddress"
                        margin="dense"
                        id="ipaddress"
                        label="IP Address"
                        type="text"
                        fullWidth
                        value={ipaddress}
                        onChange={onChange}
                        required
                        error={!!ipError}
                        helperText={ipError}
                    />

                    <TextField
                        name="serverport"
                        margin="dense"
                        id="serverport"
                        label="Port"
                        type="text"
                        fullWidth
                        value={serverport}
                        onChange={onChange}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        required
                    />
                    <TextField
                        name="localaet"
                        margin="dense"
                        id="localaet"
                        label="Local AE Title"
                        type="text"
                        fullWidth
                        value={localaet}
                        onChange={onChange}
                        inputProps={{ maxLength: 16 }}
                    />
                    <br />
                    <Collapse in={openError}>
                        <Fade
                            in={openError}
                            timeout={{ enter: 1000, exit: 1000 }}
                            addEndListener={() => {
                                setTimeout(() => {
                                    setOpenError(false);
                                    setOpenDialog(false);
                                }, 2000);
                            }}
                        >
                            <Alert sx={{ mb: 2 }} variant="filled" severity={alertSeverity}>
                                {errorText}
                            </Alert>
                        </Fade>
                    </Collapse>
                    <DialogActions>
                        <Button onClick={handleSubmit} disabled={!!serverNameError}>Add</Button>
                        <Button onClick={handleCloseDialog}>Close</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    );
};
export default AddDicomPeer;
