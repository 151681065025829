import React, { useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import BackdropIcon from "../BackdropIcon";

import FileDownloadIcon from "@mui/icons-material/FileDownload";

const DownloadStudy = (props) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadDicom = async (studyId) => {
    const session_url =
      `${process.env.REACT_APP_BACKEND_PHP_URL}/seriesdownload.php?id=` +
      studyId +
      `&sid=${props.token}`;

    axios.defaults.withCredentials = true;
    setIsDownloading(true);
    axios({
      url: session_url,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", studyId + ".zip");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        setIsDownloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  if (isDownloading) {
    return <BackdropIcon content={<FileDownloadIcon />} />;
  }

  return (
    <>
      <Button
        sx={{
          background: "#80848c",
          fontSize: "11px",
          ":hover": {
            bgcolor: "#0290A2",
            color: "black",
          },
        }}
        endIcon={<FileDownloadIcon />}
        variant="contained"
        onClick={() => {
          handleDownloadDicom(props.id);
        }}
      >
        Dicom
      </Button>
    </>
  );
};
export default DownloadStudy;
