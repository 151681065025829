import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import axios from "axios";
import jwt_decode from "jwt-decode";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Fade from "@mui/material/Fade";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

const AddUserButton = (props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");
  const [openError, setOpenError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [roleN, setRoleN] = useState("user");

  const handleChange = (event) => {
    setRoleN(event.target.value);
  };

  const axiosJWT = axios.create();
  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_NODE_URL}/token`,
          {
            withCredentials: true,
          }
        );
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const addUser = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosJWT.post(
        `${process.env.REACT_APP_BACKEND_NODE_URL}/users`,
        {
          name: name,
          nemail: email,
          password: password,
          access_level: roleN,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setErrorText(response.data.msg);
      setOpenError(true);
      setName("");
      setEmail("");
      setPassword("");
      setRoleN("user");
      props.onAdd();
    } catch (error) {
      if (error.response) {
        setErrorText(error.response.data.msg);
      }
    }
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  return (
    <>
      <Button
        // variant="outlined"
        color="primary"
        component="span"
        onClick={handleClickOpenDialog}
        startIcon={<PersonAddIcon />}
      >
        Add User
      </Button>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Add User</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="name"
            label="Name"
            type="name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="dense"
            id="name"
            label="Password"
            type="password"
            fullWidth
            placeholder="*****"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <br />
          <FormControl>
            <FormLabel id="perm-radio-buttons-group">Permission</FormLabel>
            <RadioGroup
              aria-labelledby="perm-buttons-group"
              name="perm-radio-buttons-group"
              value={roleN}
              onChange={handleChange}
            >
              <FormControlLabel value="user" control={<Radio />} label="User" />
              <FormControlLabel
                value="admin"
                control={<Radio />}
                label="Admin"
              />
            </RadioGroup>
          </FormControl>
          <Collapse in={openError}>
            <Fade
              in={openError}
              timeout={{ enter: 1000, exit: 1000 }}
              addEndListener={() => {
                setTimeout(() => {
                  setOpenError(false);
                }, 2000);
              }}
            >
              <Alert sx={{ mb: 2 }} variant="filled">
                {errorText}
              </Alert>
            </Fade>
          </Collapse>
          <DialogActions>
            <Button onClick={addUser}>Add</Button>
            <Button onClick={handleCloseDialog}>Close</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddUserButton;
