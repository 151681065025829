import React, { useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import BackdropIcon from "../BackdropIcon";

import FileDownloadIcon from "@mui/icons-material/FileDownload";

const DownloadPreview = (props) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadDicomDir = async (studyId) => {
    const session_url = `${process.env.REACT_APP_BACKEND_PHP_URL}/downloadImage.php?id=${studyId}&sid=${props.token}&ty=${props.type}`;

    setIsDownloading(true);
    axios({
      url: session_url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const contentDisposition = response.headers["content-disposition"];
      const filename = contentDisposition.split('filename="')[1]?.split('"')[0];
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setIsDownloading(false);
    });
  };
  if (isDownloading) {
    return <BackdropIcon content={<FileDownloadIcon />} />;
  }

  return (
    <>
      <Button
        sx={{
          background: "#80848c",
          fontSize: "11px",
          ":hover": {
            bgcolor: "#0290A2",
            color: "black",
          },
        }}
        endIcon={<FileDownloadIcon />}
        variant="contained"
        onClick={() => {
          handleDownloadDicomDir(props.id);
        }}
      >
        {props.type}
      </Button>
    </>
  );
};
export default DownloadPreview;
